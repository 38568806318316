@layer components {
    .app-actions {
        @apply mb-3;
        @apply last:mb-0;
    }

    .app-action {
        @apply mr-3;
        @apply last:mr-0;
        @apply mb-3;
        @apply last:mb-0;
    }

    .app-button-wrapper {
        @apply inline-block;
    }

    .app-base-icon-button {
        @apply text-inherit #{!important};
        @apply text-size-inherit-app #{!important};
    }

    .app-base-text-button {
        @apply text-inherit #{!important};
        @apply bg-inherit #{!important};
    }

    .app-text-button-primary {
        @apply text-white;
        @apply app-bg-primary;
    }

    .app-text-button-success {
        @apply text-white;
        @apply app-bg-success;
    }

    .app-text-button-warning {
        @apply text-white;
        @apply app-bg-warning;
    }

    .app-text-button-error {
        @apply text-white;
        @apply app-bg-error;
    }

    .app-icon-button-primary {
        @apply app-text-primary;
    }

    .app-icon-button-success {
        @apply app-text-success;
    }

    .app-icon-button-warning {
        @apply app-text-warning;
    }

    .app-icon-button-error {
        @apply app-text-error;
    }
}
