.app-plant-status-color {
    &.app-plant-status-in-stock {
        @apply app-text-success;
    }

    &.app-plant-status-not-in-stock {
        @apply app-text-error;
    }

    &.app-plant-status-propagation {
        @apply app-text-warning;
    }
}
