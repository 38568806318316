@layer components {
    .app-table-wrapper {
        @apply w-full;
        @apply overflow-auto;
        @apply shadow-md;
    }

    .app-table {
        @apply w-full;
        @apply table-fixed;
        @apply border-collapse;
        @apply bg-white;
    }

    .app-table-header-row,
    .app-table-row {
        @apply h-12;
    }

    .app-table-header-row {
        @apply text-xl;
    }

    .app-table-row {
        @apply hover:bg-gray-100;
    }

    .app-table-header-cell,
    .app-table-cell {
        @apply pl-1;
        @apply last:pr-1;
        @apply border-b;
        @apply border-gray-100;
    }

    .app-table-header-cell {
        @apply sticky;
        @apply top-0;
        @apply font-bold;
        @apply bg-white;
        @apply z-10;
    }

    .app-table-cell-checkbox {
        @apply w-12;
        @apply text-center;
    }

    .app-table-cell-no-data {
        @apply font-bold;
        @apply text-center;
    }
}
