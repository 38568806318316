.mat-mdc-form-field {
    @apply w-full;
}

.mat-form-field-remove-bottom {
    .mat-mdc-form-field-subscript-wrapper {
        @apply hidden;
    }
}

.mat-sidenav-container {
    @apply absolute #{!important};
    @apply top-12;
    @apply bottom-0;
    @apply w-full;
}

.mat-drawer-inner-container {
    width: 300px #{!important};
}

.mat-mdc-dialog-surface {
    @apply py-3;
    @apply overflow-hidden;
}

.mdc-snackbar__surface {
    @apply bg-inherit #{!important};
}

.mat-mdc-snack-bar-label {
    @apply text-white #{!important};
}

.mat-mdc-snack-bar-action {
    @apply text-white #{!important};
}

.mat-mdc-raised-button[disabled] {
    @apply text-inherit;
    @apply opacity-80;
}

.mat-mdc-icon-button {
    @apply inline-flex #{!important};
    @apply items-center;
    @apply justify-center;
}

.mat-mdc-tooltip-trigger {
    @apply touch-auto #{!important};
}

.mat-mdc-option {
    @apply app-break-word;
}
