@layer components {
    .app-dialog-title {
        @apply px-6;
        @apply mb-3;
        @apply font-bold;
        @apply text-center;
        @apply app-break-word;
        @apply text-xl;
    }

    .app-dialog-content {
        @apply px-6;
        @apply mb-3;
        @apply overflow-auto;
        @apply max-h-screen-65/100-app;
    }

    .app-dialog-actions {
        @apply flex;
        @apply justify-center;
        @apply px-6;
    }

    .app-dialog-action {
        @apply mr-3;
        @apply last:mr-0;
    }

    .app-base-dialog-button {
        @apply text-inherit #{!important};
    }
}
