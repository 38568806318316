@import "material";
@import "material-overrides";
@import "tailwind";
@import "fontello.css";

@import "button";
@import "dialog";
@import "form";
@import "sidenav";
@import "status-colors";
@import "table";
@import "utils";

::-webkit-file-upload-button {
    @apply cursor-pointer;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    @apply hidden;
}

*:focus {
    @apply outline-0;
}

@layer components {
    .app-link {
        @apply text-blue-600;
        @apply hover:underline;
        @apply focus:ring-2;
    }

    .app-h2 {
        @apply font-bold;
        @apply text-2xl;
    }

    .app-h2-mb {
        @apply mb-3;
    }

    .app-page {
        @apply min-h-full;
        @apply p-3;
    }

    .app-list-page {
        @apply flex;
        @apply flex-col;
        @apply overflow-hidden;
        @apply h-full;
    }

    .card-list-wrapper {
        @apply flex;
        @apply flex-wrap;
        @apply justify-center;
        @apply content-start;
        @apply items-start;
        @apply w-full;
        @apply h-full;
        @apply overflow-auto;
        @apply p-1;
    }
}
