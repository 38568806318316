// For more information: https://material.angular.io/guide/theming

@use "@angular/material" as mat;

@include mat.core();

$website-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$website-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$website-warn: mat.m2-define-palette(mat.$m2-red-palette);

$website-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $website-primary,
            accent: $website-accent,
            warn: $website-warn,
        ),
        typography: mat.m2-define-typography-config(),
        density: 0,
    )
);
$icon-button-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $website-primary,
            accent: $website-accent,
            warn: $website-warn,
        ),
        typography: mat.m2-define-typography-config(),
        density: -2,
    )
);

@include mat.core-theme($website-theme);

.app-icon-button-density-minus-4 {
    @include mat.icon-button-density(-4);
}

//@include mat.autocomplete-theme($website-theme);
//@include mat.badge-theme($website-theme);
//@include mat.bottom-sheet-theme($website-theme);
@include mat.button-theme($website-theme);
@include mat.fab-theme($website-theme);
@include mat.icon-button-theme($icon-button-theme);
//@include mat.button-toggle-theme($website-theme);
//@include mat.card-theme($website-theme);
@include mat.checkbox-theme($website-theme);
//@include mat.chips-theme($website-theme);
//@include mat.table-theme($website-theme);
//@include mat.datepicker-theme($website-theme);
@include mat.dialog-theme($website-theme);
//@include mat.divider-theme($website-theme);
//@include mat.expansion-panel-theme($website-theme);
@include mat.form-field-theme($website-theme);
//@include mat.grid-list-theme($website-theme);
//@include mat.icon-theme($website-theme);
@include mat.input-theme($website-theme);
//@include mat.list-theme($website-theme);
@include mat.menu-theme($website-theme);
//@include mat.paginator-theme($website-theme);
//@include mat.progress-bar-theme($website-theme);
@include mat.progress-spinner-theme($website-theme);
//@include mat.radio-theme($website-theme);
@include mat.select-theme($website-theme);
@include mat.sidenav-theme($website-theme);
//@include mat.slide-toggle-theme($website-theme);
//@include mat.slider-theme($website-theme);
//@include mat.stepper-theme($website-theme);
//@include mat.sort-theme($website-theme);
//@include mat.tabs-theme($website-theme);
//@include mat.toolbar-theme($website-theme);
@include mat.tooltip-theme($website-theme);
//@include mat.tree-theme($website-theme);
@include mat.snack-bar-theme($website-theme);
