@font-face {
    font-family: "fontello";
    src: url("/assets/font/fontello.eot?24884103");
    src:
        url("/assets/font/fontello.eot?24884103#iefix")
            format("embedded-opentype"),
        url("/assets/font/fontello.woff2?24884103") format("woff2"),
        url("/assets/font/fontello.woff?24884103") format("woff"),
        url("/assets/font/fontello.ttf?24884103") format("truetype"),
        url("/assets/font/fontello.svg?24884103#fontello") format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('/assets/font/fontello.svg?24884103#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    /*margin-right: .2em;*/
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /*margin-left: .2em;*/

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-pencil:before {
    content: "\e800";
} /* '' */
.icon-trash-empty:before {
    content: "\e803";
} /* '' */
.icon-search:before {
    content: "\e804";
} /* '' */
.icon-plus:before {
    content: "\e806";
} /* '' */
.icon-attention:before {
    content: "\e807";
} /* '' */
.icon-inbox:before {
    content: "\e808";
} /* '' */
.icon-wrench:before {
    content: "\e809";
} /* '' */
.icon-login:before {
    content: "\e80a";
} /* '' */
.icon-logout:before {
    content: "\e80b";
} /* '' */
.icon-cancel:before {
    content: "\e80c";
} /* '' */
.icon-info-circled:before {
    content: "\e80d";
} /* '' */
.icon-menu:before {
    content: "\f0c9";
} /* '' */
.icon-gauge:before {
    content: "\f0e4";
} /* '' */
.icon-upload-cloud:before {
    content: "\f0ee";
} /* '' */
.icon-doc-text:before {
    content: "\f0f6";
} /* '' */
.icon-info:before {
    content: "\f129";
} /* '' */
.icon-ellipsis-vert:before {
    content: "\f142";
} /* '' */
.icon-file-excel:before {
    content: "\f1c3";
} /* '' */
.icon-address-card-o:before {
    content: "\f2bc";
} /* '' */
