@layer utilities {
    .app-text-primary {
        @apply text-blue-600;
    }

    .app-text-success {
        @apply text-green-600;
    }

    .app-text-warning {
        @apply text-yellow-600;
    }

    .app-text-error {
        @apply text-red-600;
    }

    .app-bg-primary {
        @apply bg-blue-600;
    }

    .app-bg-success {
        @apply bg-green-600;
    }

    .app-bg-warning {
        @apply bg-yellow-600;
    }

    .app-bg-error {
        @apply bg-red-600;
    }

    //todo: break-word is deprecated, but ignore this fact for now https://github.com/tailwindlabs/tailwindcss/discussions/2213
    .app-break-word {
        word-break: break-word;
    }

    .app-icon-size-5 {
        font-size: 1.25rem;
    }
}
