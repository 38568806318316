@layer components {
    .app-form {
        @apply block;
        @apply mb-3;
    }

    .app-form-item {
        @apply flex;
        @apply items-start;
        @apply max-w-80-app;
        @apply min-h-20-app;
        @apply mb-3;
        @apply last:mb-0;
    }

    .app-form-item-wide {
        @apply max-w-full;
    }

    .app-input-wrapper {
        @apply block;
        @apply w-full;
    }
}
