@layer components {
    .app-sidenav-item {
        @apply flex;
        @apply items-stretch;
        @apply w-full;
        @apply min-h-12-app;
    }

    .app-sidenav-item-link {
        @apply flex;
        @apply items-center;
        @apply flex-1;
        @apply px-4;
        @apply app-break-word;
        @apply hover:bg-gray-100;
        @apply focus:bg-gray-100;
    }

    .app-sidenav-item-link-active {
        @apply bg-gray-100;
    }

    .app-sidenav-item-icon {
        @apply app-icon-size-5;
        @apply ml-1;
    }

    .app-sidenav-item-actions {
        @apply flex;
        @apply items-center;
    }
}
